import React from "react"
import styled from "styled-components"
import MasterLayout from "../../components/MasterLayout"
import Container from "../../components/Container"
import { rhythm } from "../../utils/typography"

import { useStaticQuery, graphql, Link } from "gatsby"

const TitleWrapper = styled.h3`
  margin-bottom: ${rhythm(3 / 4)};
`

const useSitedata = () => {
  const data = useStaticQuery(
    graphql`
      query($type: String = "articles") {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { fields: { type: { eq: $type } } }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `
  )
  return data
}

export default ({ children }) => {
  const { allMarkdownRemark, site } = useSitedata()
  const posts = allMarkdownRemark.edges

  return (
    <MasterLayout>
      <Container>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <TitleWrapper>
                <Link to={node.fields.slug}>{title}</Link>
              </TitleWrapper>
              <small>{node.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </Container>
    </MasterLayout>
  )
}
